import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import Newsletter from "components/Newsletter"

import { Content, Knowledge } from "page_components/wallpapers"

import ConfigProvider from "context/config"
import SearcherProvider from "context/searcher"
import { useProducts } from "context/products"

const WallpapersContent = ({ location, pageData }) => {
  const { productsEN } = useProducts()

  const formatted_products = productsEN?.filter(item =>
    item?.productCategories?.nodes?.some(item => item?.slug === "tapety")
  )

  return (
    <ConfigProvider location={location} products={formatted_products}>
      <SearcherProvider location={location}>
        <Content pageData={pageData} />
      </SearcherProvider>
    </ConfigProvider>
  )
}

const Wallpapers = ({ location, data }) => {
  const pageData = data?.allWpPage?.nodes[0]

  return (
    <Layout location={location} key={location?.search}>
      <Breadcrumbs title={pageData?.title} />
      <PageHeader title={pageData?.title} />
      <WallpapersContent location={location} pageData={pageData} />
      <Knowledge />
      <Newsletter />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Exclusive Custom Wallpapers - wallcraft.com.pl"
    description="Explore our exclusive custom wallpapers that bring life to any interior. Choose perfect patterns and colors and create a unique space with wallcraft.com.pl."
  />
)

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxNzI1Mw==" } }) {
      nodes {
        title
        content
      }
    }
  }
`

export default Wallpapers
